import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "首页",
    component: () => import("@/views/index")
  },
  {
    path: "/about",
    name: "关于收拾车",
    component: () => import("@/views/about")
  },
  {
    path: "/companyNews",
    name: "公司动态",
    component: () => import("@/views/companyNews")
  },
  {
    path: "/companyNewsDetail",
    name: "公司动态详情",
    component: () => import("@/views/companyNewsDetail")
  },
  {
    path: "/recruit",
    name: "招贤纳士",
    component: () => import("@/views/recruit")
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
