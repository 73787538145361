import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import VueParticles from "vue-particles";

import * as echarts from "echarts/lib/echarts";
import "echarts-liquidfill";
Vue.use(VueParticles);
import 'bootstrap'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

import "element-ui/lib/theme-chalk/index.css";
import "swiper/swiper-bundle.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
import 'bootstrap/dist/css/bootstrap.css';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_API,
window.bus = new Vue();
window.router = router;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
